<template>
  <div class="page-out">
    <div v-if="confirmResult === 1" :class="isPcMobileClass()">
      <div class="page-box">
        <div class="img-box">
          <img
            :src="defaultImg"
            alt=""
          >
        </div>
        <div class="page-title">
          {{ defaultText}}
        </div>

        <div class="displayColumn">
          <div class="displayColumn-box">
            <div class="displayColumn-txt">分成周期</div>
            <div class="displayColumn-num">{{cycle}}</div>
          </div>
          <div class="displayColumn-box">
            <div class="displayColumn-txt">分成比例</div>
            <div class="displayColumn-num">{{proportion}}</div>
          </div>
        </div>

        <div class="page-content">
          <view-match :list="basicList" />
        </div>
        <div
          class="bottom"
        >
          <cube-button
            class="btn-turnDown"
            :light="true"
             @click="submitHandler(3)"
          >
            驳回
          </cube-button>
          <cube-button
            class="btns"
            @click="submitHandler(2)"
          >
            同意
          </cube-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import viewMatch from '_c/view-match';
import { toArrays, isPcMobile } from '@/lib/until.js';
import { commissionShareDetail, commissionShareConfirm } from '@/api/contact';
import { mapGetters } from 'vuex';
export default {
  components: {
    viewMatch
  },
  data() {
    return {
      confirmResult: '',
      defaultImg: require('../../assets/img/icon_tips_tongzhi@2x.png'),
      defaultText: '提成分成确认',
      basicData: {
        custCn: {
          title: '客户名称',
          value: ''
        },
        comboName: {
          title: '产品名称',
          value: ''
        },
        sellerUserName: {
          title: '所属销售',
          value: ''
        },
        effectDate: {
          title: '合同有效期',
          value: ''
        },
        createTime: {
          title: '分成添加时间',
          value: ''
        }
      },
      cycle: '',
      proportion: ''
    };
  },
  computed: {
    ...mapGetters(['dictList']),
    basicList() { // 对象是引用类型，传递的是引用地址，所以两个数组引用的是同一个对象，只要其中一个数组改变，就会导致对象改变，进而另一个引用的数组也会改。
      return toArrays(this.basicData);
    }
  },
  mounted() {
    this.getDetail();
  },
  methods: {
    getDetail() {
      console.log(this.$route.query);
      commissionShareDetail(this.$route.query.id, this.$route.query.confirmId).then(res =>{
        if (res.flag) {
          this.confirmResult = res.data.confirmResult;
          switch(res.data.confirmResult) {
            case 1: 
              let data = res.data;
              for(let i in this.basicData) {
                this.basicData[i].value = data[i];
              }
              this.basicData.effectDate.value = data.effectStartDate + ' ~ ' + data.effectEndDate;
              this.cycle = data.wholeCycle === true ? '合同有效期' : data.dividedCycle + '个月';
              this.proportion = data.shareProportion + '%';
              break;
            case 2: 
              this.$router.push(
                {
                  name: 'StutesPage',
                  query: {
                    type: '1',
                    text: '提成分成已同意'
                  }
                }
              );
              break;
            case 3:
              this.$router.push(
                {
                  name: 'StutesPage',
                  query: {
                    type: '2',
                    text: '提成分成已驳回'
                  }
                }
              );
          }
        } else {
          this.$showToast(res.errMsg);
        }
      })
    },
    submitHandler(confirmResult) {
      // confirmResult 1待确认 2已确认 3已驳回
      commissionShareConfirm(this.$route.query.id, this.$route.query.confirmId, confirmResult).then(res => {
        if (res.flag) {
          this.$router.push(
            {
              name: 'StutesPage',
              query: {
                type: confirmResult === 2 ? '1' : '2',
                text: confirmResult === 2 ? '提成分成已同意' : '提成分成已驳回'
              }
            }
          );
          this.$showToast( confirmResult === 2 ?  '已确认' : '已驳回');
        } else {
          this.$showToast(res.errorMsg);
        }
      })
    },
    isPcMobileClass() {
      return isPcMobile() === 2 ? 'screenControl' : '';
    }
  }
};
</script>

<style scoped lang="scss">
@import '@/assets/css/components/btn-bottom.scss';
.page-out{
  background-color: $color-F7;
  .page-box{
    margin: 15px 15px 0 15px;
    overflow: hidden;
    background-color: $color-FFF;
    border-radius: 8px 8px 0 0;
    .img-box{
      width: 60px;
      height: 60px;
      margin: 32px auto;
      margin-bottom: 24px;
      img {
        width: 60px;
      }
    }
    .page-title{
      margin-bottom: 32px;
      color: #23252E;
      font-weight: 600;
      font-size: 18px;
      line-height: 22px;
    }
    .page-content{
      margin: 32px 16px 0 16px;
      // border-bottom:1px solid #EAEBED;
    }
    .displayColumn{
      display: flex;
      justify-content: space-around;
      height: 94px;
      margin: 0 16px;
      background-color: #FAFBFC;
      border-radius: 4px;
      .displayColumn-box{
        width: 50%;
        height: 46px;
        margin: 24px 0;
        text-align: center;
        .displayColumn-txt{
          margin-bottom: 8px;
          color:#999BA3 ;
          font-size: 14px;
          line-height: 18px;
        }
        .displayColumn-num{
          color:#23252E ;
          font-weight: 600;
          font-size: 16px;
          line-height: 20px;
        }
      }
      .displayColumn-box:nth-child(2n-1) {
        border-right: 1px solid #EAEBED;
      }
    }
  }
}
</style>
